import { FEATURE_FLAGS } from '@smf/ui-util-app';

export const SCREENS = {
  CONNECTIVITY_MDU: 'Connectivity',
  GENERAL_CONFIG: 'General',
  FACTORY_HEALTH: 'Factory Health',
  SOC_CONFIG: 'Standard Operating Conditions',
  OEE: 'OEE',
  CONSTRAINTS: 'Constraints',
  NOTIFICATION_CONFIG: 'Notifications',
  POS_CONFIG: 'POS Configuration',
  PM_CONFIG: 'PM Configuration',
  SPC_CONFIG: 'Spc Configuration',
  DIGITAL_TWIN_CONFIG: 'Digital Twin Configuration',
  PPE_INSIGHTS_CONFIG: 'PPE Insights Configuration',
  GENCI_CONFIG: 'GenCI Configuration',
  DYNAMIC_SCHEDULER_CONFIG: 'Dynamic Scheduler Configuration',
  YP_CONFIG: 'YP Configuration',
  VI_CONFIG: 'Visual Inspection Configuration',
};

export const MESSAGE_STRINGS = {
  'GeneralConfigSection.mduConnectivity': 'Connectivity',
  'GeneralConfigSection.generalConfig': 'General',
  // Factory Setting Sections
  'FactorySettingSection.factoryHealth': 'Factory Health',
  'SocConfigSection.socConfig': 'Standard Operating Conditions',
  'OeeConfigSection.oeeConfig': 'OEE',
  'NotifConfigSection.notificationConfig': 'Notification Configuration',
  'PosConfigSection.posConfig': 'Production Order Sequencing Configuration',
  'PmConfigSection.pmConfig': 'Predictive Maintenance Configuration',
  'SpcConfigSection.spcConfig': 'Smart SPC Configuration',
  'YieldPredictionConfiguration.ypConfig': 'Yield Prediction',
  'DigitalTwinConfiguration.digitalTwinConfig': 'Digital Twin',
  'PPEInsightsConfiguration.ppeInsightsConfig': 'PPE Insights Configuration',
  'ConstraintsConfig.constraints': 'Equipment Constraints',
  //Plant Details Texts
  'PlantDetails.companyName': 'Company Name',
  'PlantDetails.businessUnit': 'Business Unit',
  'PlantDetails.plantName': 'Plant Name',
  'GenCIConfigSection.genCIConfig': 'GenCI',
  'DynamicSchedulerConfigSection.dynamicSchedulerConfig': 'Dynamic Scheduler',
  'VisualInspectionConfiguration.viConfig': 'Visual Inspection',
};

export const CONFIG_HEADINGS = {
  CONFIG: 'Configuration',
  FACTORY_CONFIG: 'Factory Configuration',
};

export const URL = {
  CONFIG: '/config',
  GENERAL_CONFIG: '/general_config',
  FACTORY_HEALTH: '/factory_health',
  SOC_CONFIG: '/standard_operating_conditions',
  OEE: '/oee',
  CONSTRAINTS_CONFIG: '/constraints_configuration',
  NOTIFICATION_CONFIG: '/notifications',
  POS_CONFIG: '/pos_configuration',
  PM_CONFIG: '/pm_configuration',
  SPC_CONFIG: '/smart_spc_configuration',
  CONNECTIVITY_MDU: '/mdu_connectivity',
  DIGITAL_TWIN_CONFIG: '/digital_twin',
  PPE_INSIGHTS_CONFIG: '/ppe_insights',
  GENCI_CONFIG: '/genci_configuration',
  YP_CONFIG: '/yp_configuration',
  VI_CONFIG: '/vi_configuration',
  DYNAMIC_SCHEDULER_CONFIG: '/dynamic_scheduler_configuration',
};

export const CONFIG_STATUSES = {
  NOT_STARTED: 'Not Started',
  IN_PROGRESS: 'In Progress',
  COMPLETED: 'Completed',
};

export const CONFIG_NAMES = {
  FACTORY_HEALTH: 'factoryHealth',
};

// Status Keys used to render in Config Sidebar
export const configStatusKeys = {
  [SCREENS.GENERAL_CONFIG]: 'factoryGeneral',
  [SCREENS.FACTORY_HEALTH]: 'factoryHealth',
  [SCREENS.SOC_CONFIG]: 'soc',
  [SCREENS.OEE]: 'oee',
  [SCREENS.CONSTRAINTS]: 'constraints',
  [SCREENS.CONNECTIVITY_MDU]: 'connectivity',
};

export const GENCI_MODULES = {
  VIRTUAL_ASSISTANT: 'Virtual Assistant',
  USER_CHAT: 'User Chat',
};

export const CATEGORY_KEY_FEATURE_FLAG_MAP = {
  [SCREENS.POS_CONFIG]: FEATURE_FLAGS.POS,
  [SCREENS.SPC_CONFIG]: FEATURE_FLAGS.SMART_SPC,
  [SCREENS.DIGITAL_TWIN_CONFIG]: FEATURE_FLAGS.DT,
  [SCREENS.GENCI_CONFIG]: {
    [GENCI_MODULES.VIRTUAL_ASSISTANT]: FEATURE_FLAGS.GEN_CI.VIRTUAL_ASSISTANT,
    [GENCI_MODULES.USER_CHAT]: FEATURE_FLAGS.GEN_CI.CHAT_BOT,
  },
  [SCREENS.YP_CONFIG]: FEATURE_FLAGS.YP,
  [SCREENS.VI_CONFIG]: FEATURE_FLAGS.VISUAL_INSPECTION,
};

export const FEATURE_FLAG_TREATMENTS = {
  ON: 'on',
  OFF: 'off',
};
