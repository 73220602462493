import {
  CATEGORY_KEY_FEATURE_FLAG_MAP,
  FEATURE_FLAG_TREATMENTS,
  GENCI_MODULES,
  SCREENS,
  URL,
} from '../constants';
export const getURLFromPageName = (pageName) => {
  switch (pageName) {
    case SCREENS.GENERAL_CONFIG:
      return URL.GENERAL_CONFIG;
    case SCREENS.FACTORY_HEALTH:
      return URL.FACTORY_HEALTH;
    case SCREENS.SOC_CONFIG:
      return URL.SOC_CONFIG;
    case SCREENS.OEE:
      return URL.OEE;
    case SCREENS.CONSTRAINTS:
      return URL.CONSTRAINTS_CONFIG;
    case SCREENS.NOTIFICATION_CONFIG:
      return URL.NOTIFICATION_CONFIG;
    case SCREENS.DIGITAL_TWIN_CONFIG:
      return URL.DIGITAL_TWIN_CONFIG;
    case SCREENS.PPE_INSIGHTS_CONFIG:
      return URL.PPE_INSIGHTS_CONFIG;
    case SCREENS.CONNECTIVITY_MDU:
      return URL.CONNECTIVITY_MDU;
    case SCREENS.GENCI_CONFIG:
      return URL.GENCI_CONFIG;
    case SCREENS.VI_CONFIG:
      return URL.VI_CONFIG;
    default:
      return URL.GENERAL_CONFIG;
  }
};

export const getPageNameFromUrl = (url) => {
  switch (url) {
    case URL.GENERAL_CONFIG:
      return SCREENS.GENERAL_CONFIG;
    case URL.FACTORY_HEALTH:
      return SCREENS.FACTORY_HEALTH;
    case URL.SOC_CONFIG:
      return SCREENS.SOC_CONFIG;
    case URL.NOTIFICATION_CONFIG:
      return SCREENS.NOTIFICATION_CONFIG;
    case URL.DIGITAL_TWIN_CONFIG:
      return SCREENS.DIGITAL_TWIN_CONFIG;
    case URL.PPE_INSIGHTS_CONFIG:
      return SCREENS.PPE_INSIGHTS_CONFIG;
    case URL.CONSTRAINTS_CONFIG:
      return SCREENS.CONSTRAINTS;
    case URL.OEE:
      return SCREENS.OEE;
    case URL.CONNECTIVITY_MDU:
      return SCREENS.CONNECTIVITY_MDU;
    case URL.GENCI_CONFIG:
      return SCREENS.GENCI_CONFIG;
    case URL.VI_CONFIG:
      return SCREENS.VI_CONFIG;
    default:
      return null;
  }
};

export const getFlagsWithTreatment = (
  featureFlags = {},
  treatment = FEATURE_FLAG_TREATMENTS.ON
) => {
  return Object.keys(featureFlags).filter(
    (flagKey) => featureFlags[flagKey]?.treatment === treatment
  );
};

export const getFilteredCategories = (
  categories = [],
  flagsWithOffTreatment = []
) => {
  return categories?.filter((category) => {
    if (category.name === SCREENS.GENCI_CONFIG) {
      return !flagsWithOffTreatment.includes(
        CATEGORY_KEY_FEATURE_FLAG_MAP[SCREENS.GENCI_CONFIG][
          GENCI_MODULES.USER_CHAT
        ]
      );
    }
    return !flagsWithOffTreatment.includes(
      CATEGORY_KEY_FEATURE_FLAG_MAP[category.name]
    );
  });
};
