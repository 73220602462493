import React, { useState, useEffect } from 'react';
import { Box, Grid, styled, Typography } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { SplitTreatments } from '@splitsoftware/splitio-react';

import {
  globalstate$,
  updateEditState,
  EDIT_STATE_KEYS,
  FEATURE_FLAGS,
  RBAC_CONSTANTS,
} from '@smf/ui-util-app';
import PageSideBar from '../../components/PageSideBar';
import {
  SCREENS,
  MESSAGE_STRINGS,
  CONFIG_HEADINGS,
  URL,
  configStatusKeys,
  FEATURE_FLAG_TREATMENTS,
} from '../../constants';
import {
  getURLFromPageName,
  getPageNameFromUrl,
  getFilteredCategories,
  getFlagsWithTreatment,
} from '../../utils/helpers';
import { useRxjsState } from '../../utils/useRxjsState';
import LoadingIndicator from '../../components/LoadingIndicator';
import CommonBackdrop from '../../components/CommonBackdrop';

const TitleTypography = styled(Typography)(({ theme }) => ({
  fontSize: '0.875rem',
  color: theme.palette.text.lightYellowishGray,
  lineHeight: '1.192rem',
  fontWeight: '400',
}));
const DescriptionTypography = styled(Typography)(() => ({
  fontSize: '0.875rem',
  fontWeight: '700',
  lineHeight: '1.192rem',
}));
const PlantNameTypography = styled(Typography)(() => ({
  fontWeight: '700',
  fontSize: '1.125rem',
  lineHeight: '1.532rem',
}));
const PlantDetailsBox = styled(Box)(({ theme }) => ({
  margin: '0.625rem 0.625rem 1rem 0.625rem',
  paddingTop: '0.75rem',
  paddingLeft: '1.019rem',
  backgroundColor: theme.customColors.nero,
  height: '13.625rem',
  display: 'flex',
  flexDirection: 'column',
}));

const initialFactoryConfigOptions = [
  {
    name: SCREENS.CONNECTIVITY_MDU,
    isDisabled: false,
    label: MESSAGE_STRINGS['GeneralConfigSection.mduConnectivity'],
    key: configStatusKeys[SCREENS.CONNECTIVITY_MDU],
  },
  {
    name: SCREENS.GENERAL_CONFIG,
    isDisabled: false,
    label: MESSAGE_STRINGS['GeneralConfigSection.generalConfig'],
    key: configStatusKeys[SCREENS.GENERAL_CONFIG],
  },
  {
    name: SCREENS.FACTORY_HEALTH,
    isDisabled: false,
    label: MESSAGE_STRINGS['FactorySettingSection.factoryHealth'],
    key: configStatusKeys[SCREENS.FACTORY_HEALTH],
  },
  {
    name: SCREENS.OEE,
    isDisabled: false,
    label: MESSAGE_STRINGS['OeeConfigSection.oeeConfig'],
    key: configStatusKeys[SCREENS.OEE],
  },
  {
    name: SCREENS.SOC_CONFIG,
    isDisabled: false,
    label: MESSAGE_STRINGS['SocConfigSection.socConfig'],
    key: configStatusKeys[SCREENS.SOC_CONFIG],
  },
  {
    name: SCREENS.CONSTRAINTS,
    isDisabled: false,
    label: MESSAGE_STRINGS['ConstraintsConfig.constraints'],
    key: configStatusKeys[SCREENS.CONSTRAINTS],
  },
];

const otherConfigOptions = [
  {
    name: SCREENS.NOTIFICATION_CONFIG,
    isDisabled: false,
    label: MESSAGE_STRINGS['NotifConfigSection.notificationConfig'],
    showConfigStatus: false,
  },
  {
    name: SCREENS.DIGITAL_TWIN_CONFIG,
    isDisabled: false,
    label: MESSAGE_STRINGS['DigitalTwinConfiguration.digitalTwinConfig'],
    showConfigStatus: false,
  },
  {
    name: SCREENS.PPE_INSIGHTS_CONFIG,
    isDisabled: false,
    label: MESSAGE_STRINGS['PPEInsightsConfiguration.ppeInsightsConfig'],
    showConfigStatus: false,
  },
  {
    name: SCREENS.GENCI_CONFIG,
    isDisabled: false,
    label: MESSAGE_STRINGS['GenCIConfigSection.genCIConfig'],
    showConfigStatus: false,
  },
  {
    name: SCREENS.VI_CONFIG,
    isDisabled: false,
    label: MESSAGE_STRINGS['VisualInspectionConfiguration.viConfig'],
    showConfigStatus: false,
  },
];

function ConfigSidebar() {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedOption, setSelectedOption] = useState(null);

  const { rxjsState } = useRxjsState();

  useEffect(() => {
    // Sets the selected option correctly in the left side bar based on URL
    // Handles the scenario when user changes the URL directly from the browser URL bar
    const pathnameComponents = location.pathname.split('/');
    const [lastItem] = pathnameComponents.slice(-1);
    setSelectedOption(getPageNameFromUrl(`/${lastItem}`));
  }, [location.pathname]);

  const screensEnabledByDefault = [
    SCREENS.CONNECTIVITY_MDU,
    SCREENS.GENERAL_CONFIG,
  ];

  const factoryConfigOptions = initialFactoryConfigOptions.map((option) => ({
    ...option,
    showConfigStatus: true,
    // ? if general configuration is incomplete, disable all other tabs except connectivity and general config
    isDisabled:
      !screensEnabledByDefault.includes(option.name) &&
      !rxjsState?.isFGCComplete,
  }));

  const sections = [
    {
      heading: CONFIG_HEADINGS.FACTORY_CONFIG,
      options: factoryConfigOptions,
    },
    {
      options: otherConfigOptions,
    },
  ];

  const renderPageSideBar = (treatments) => {
    const flagsWithOffTreatment = getFlagsWithTreatment(
      treatments,
      FEATURE_FLAG_TREATMENTS.OFF
    );

    sections[1].options = getFilteredCategories(
      sections[1].options,
      flagsWithOffTreatment
    );

    if (
      !(
        rxjsState.userData?.groups.includes(
          RBAC_CONSTANTS.ADMIN_ROLES.GLOBAL
        ) ||
        rxjsState.userData?.groups.includes(
          RBAC_CONSTANTS.ADMIN_ROLES.BUSINESS_UNIT
        ) ||
        rxjsState.userData?.groups.includes(RBAC_CONSTANTS.ADMIN_ROLES.PLANT)
      )
    ) {
      sections[1].options = sections[1].options.filter(
        (otherConfig) =>
          otherConfig.name !== SCREENS.DIGITAL_TWIN_CONFIG &&
          otherConfig.name !== SCREENS.YP_CONFIG
      );
    }
    return (
      <PageSideBar.DefaultMenu
        sections={sections}
        selectedOption={selectedOption}
        onMenuItemClick={onMenuItemclick}
      />
    );
  };

  function onMenuItemclick(e, name) {
    if (
      location?.pathname === `${URL.CONFIG}${URL.GENERAL_CONFIG}` &&
      globalstate$?._value.factoryGeneralEditState?.isUnsavedChange
    ) {
      const state = globalstate$.getValue();
      globalstate$.next({
        ...state,
        factoryGeneralEditState: {
          ...state.factoryGeneralEditState,
          action: {
            isUserNavigating: true,
            location: `${URL.CONFIG}${getURLFromPageName(name)}`,
            navigate: navigate,
          },
        },
      });
      return;
    }
    if (
      location?.pathname === `${URL.CONFIG}${URL.SOC_CONFIG}` &&
      globalstate$?._value.socEditState?.isUnsavedRecipe
    ) {
      updateEditState(
        {
          isUserNavigating: true,
          location: `${URL.CONFIG}${getURLFromPageName(name)}`,
        },
        {},
        EDIT_STATE_KEYS.SOC_EDIT_STATE
      );
      return;
    }
    navigate(`${URL.CONFIG}${getURLFromPageName(name)}`);
  }

  return (
    <Grid container>
      <Grid item sm={12}>
        <PageSideBar>
          <PlantDetailsBox>
            <Box sx={{ marginBottom: '1rem' }}>
              <TitleTypography>
                {MESSAGE_STRINGS['PlantDetails.companyName']}
              </TitleTypography>
              <DescriptionTypography>
                {globalstate$._value.companyData.companyName || 'Deloitte'}
              </DescriptionTypography>
            </Box>
            <Box sx={{ marginBottom: '1rem' }}>
              <TitleTypography>
                {MESSAGE_STRINGS['PlantDetails.businessUnit']}
              </TitleTypography>
              <DescriptionTypography>
                {globalstate$._value.businessUnit || 'ReactJS'}
              </DescriptionTypography>
            </Box>
            <Box>
              <TitleTypography>
                {MESSAGE_STRINGS['PlantDetails.plantName']}
              </TitleTypography>
              <PlantNameTypography>
                {globalstate$._value.plantName || 'Kansas Plant'}
              </PlantNameTypography>
            </Box>
          </PlantDetailsBox>
          <Box marginX={2}>
            <PageSideBar.Divider variant="fullWidth" />
          </Box>

          <SplitTreatments
            names={[
              FEATURE_FLAGS.DT,
              FEATURE_FLAGS.GEN_CI.VIRTUAL_ASSISTANT,
              FEATURE_FLAGS.GEN_CI.CHAT_BOT,
              FEATURE_FLAGS.YP,
              FEATURE_FLAGS.VISUAL_INSPECTION,
            ]}
          >
            {({ treatments, isReady }) => {
              return isReady ? (
                renderPageSideBar(treatments)
              ) : (
                <CommonBackdrop open>
                  <LoadingIndicator />
                </CommonBackdrop>
              );
            }}
          </SplitTreatments>
        </PageSideBar>
      </Grid>
    </Grid>
  );
}

export default ConfigSidebar;
